<template>
  <div class="mapPlate">
    <el-amap
      class="AMap"
      ref="map"
      :amap-manager="amapManager"
      :zoom="zoom"
      :center="marker"
      vid="amapDemo"
    >
      <!-- :dragEnable="true"
      :zoomEnable="false" -->
      <el-amap-marker :position="marker"></el-amap-marker>
    </el-amap>
  </div>
</template>

<script>
import { AMapManager } from 'vue-amap'
const amapManager = new AMapManager()
export default {
  name: 'Amap',
  props: {
    title: {
      type: String,
      default: '园区位置'
    },
    address: {
      type: String,
      default: '深圳市南山区深圳湾生态园'
    },
    marker:{
      type:Array,
      default:()=>[116.397451, 39.909187]
    }
  },
  data() {
    return {
      amapManager,
      zoom: 16, // 缩放比例
    }
  },
}
</script>

<style lang="less" scoped>
.mapPlate {
  /deep/.AMap {
    height: 558px;
    .amap-logo {
      display: none !important;
    }
    .amap-copyright {
      display: none !important;
    }
  }
}
</style>