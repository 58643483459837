<template>
  <div class="park-breadcrumb">
    <div class="main">
      <a-breadcrumb :routes="routes[routesItem]" class="breadcrumb-box">
       <span slot="separator"><a-icon type="right" /></span> 
       <template
          slot="itemRender"
          slot-scope="{ route }"
        >
        <span @click="goTo(route.routeName)">  {{ route.breadcrumbName }} </span>
        </template>
      </a-breadcrumb>
    </div>
  </div>
</template>

<script>
import routes from '../config/breadcrumb'
export default {
  name: 'ParkBreadcrumb',
  created(){
    this.routesItem = this.$route.name
  },
  data() {
    return {
      routes,
    };
  },
  methods:{
    goTo(name){
      this.$router.push({ name, params: { id: this.$route.params.id } })
    }
  }
};
</script>

<style lang="less" scoped>
@import '../style/var.less';
.park-breadcrumb {
  height: @headHeight;
  background-color: #f7f9fa;
  color: #909092;
  font-size: 14px;
  .main {
    position: relative;
    height: 100%;
    .breadcrumb-box {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  /deep/.ant-breadcrumb {
    a {
      color: #909092;
      font-size: 14px;
    }
    & > span{
      cursor: pointer;
    }
    & > span:last-child {
      color: #1677ff;
      pointer-events: none;
    }
  }
}
</style>
