<!--
 * @Author: yejincheng
 * @Date: 2020-12-19 14:47:14
 * @LastEditors: yejincheng
 * @LastEditTime: 2020-12-19 16:17:07
 * @Description: 
-->

<template>
  <div class='popContainer' @mousewheel.prevent >
      <a-icon class="closeIcon" type="close" @click="hideDialog" />
      <div class="swiper">
        <a-carousel ref="swiper" :beforeChange="bigChange" :dots="false" arrows>
          <div
            slot="prevArrow"
            class="custom-slick-arrow"
            style="left: -80px;zIndex: 1"
          >
            <a-icon type="left"  class="left-icon"/>
          </div>
          <div slot="nextArrow" class="custom-slick-arrow" style="right: -80px">
            <a-icon type="right" />
          </div>
          <div 
            v-for="(item,index) in photos" 
            :key="index" 
            :style="`height: 675px; background:url(${item}); background-size: cover`">
          </div>
        </a-carousel>
      </div>
      <div class="smallList">
        <div :class="`${index === bigIndex ? 'smallList-span-activate' : ''} smallList-span`" v-for="(item,index) in photos" :key="index" @click="gotoIndex(index)">
          <img width="auto" height="60px" :src="item">
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name:'BigPicSwiper',
  props:{
    photos:{
      type: Array,
      default: () => []
    },
    dialog:{
      type: Boolean,
      default: false
    },
    defIndex:{
      type: [Number,String],
      default: 5
    }
  },
  data() {
    return {
      bigIndex: 0
    }
  },
  mounted() {
    this.$refs.swiper.goTo(this.defIndex, true)
  },
  methods: {
    hideDialog() {
      this.$emit('update:dialog', false)
    },
    bigChange(form,to) {
      console.log(form,to);
      this.bigIndex = to
    },
    gotoIndex(index) {
      this.$refs.swiper.goTo(index)
    }
  },
}
</script>

<style lang="less" scoped>
@import '@/style/var.less';
.popContainer{
  -moz-user-select:none;/*火狐*/
  -webkit-user-select:none;/*webkit浏览器*/
  -ms-user-select:none;/*IE10*/
  -khtml-user-select:none;/*早期浏览器*/
  user-select:none;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000px;
  background: rgba(0,0,0,0.6);
  // overflow: hidden;
  padding: 5% 0;
  .closeIcon {
    font-size: 36px;
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 50px;
    right: 50px;
  }
  .swiper {
    width: 1200px;
    margin: 0 auto;
  }
  /deep/ .ant-carousel {
    .slick-slide {
      text-align: center;
      height: 675px;
      line-height: 675px;
      // background: #364d79;
      overflow: hidden;
      h3 {
        color: #fff;
      }
    }
    .custom-slick-arrow {
      display: flex!important;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      font-size: 24px;
      color: #909092 ;
      background: #F4F6F8;
      border-radius: 50%;
      opacity: 0.5;
      &:before {
        display: none;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .smallList {
    height: 100px;
    margin-top: 30px;
    text-align: center;
    width: auto;
    &-span {
      margin-left: 15px;
      opacity: .5;
      cursor: pointer;
      display: inline-block;
    }
    &-span-activate {
      opacity: 1;
    }
  }
}


</style>