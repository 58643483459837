<!--
 * @Author: WangHan
 * @Date: 2020-12-19 15:03:11
 * @LastEditors: WangHan
 * @LastEditTime: 2020-12-21 11:21:57
 * @Description: 
-->
<template>
  <div class="container">
    <Head />
    <!-- <div class="policy"> -->
      <ParkBreadcrumb/>
      <!-- <div class="main"> -->
        <List 
          class="main"
          :hasData.sync="hasData"
          :requestConfig="requestConfig" 
          :handleResultData="res => res.data"
          @itemClick="handleItemClick"
          />
        
      <!-- </div> -->
    <!-- </div> -->
    <BottomInfo :class="!hasData ? 'no-data' : ''"/>
    
  </div>
</template>

<script>
import List from '@/components/List'
import BottomInfo from '@/components/BottomInfo'
import Head from '@/components/Head';
import ParkBreadcrumb from '@/components/ParkBreadcrumb'
export default {
  name: 'InvestmentPolicy',
  components: {
    List,
    BottomInfo,
    Head,
    ParkBreadcrumb
  },
  data() {
    return {
      hasData: true,
      requestConfig: {
        url: '/ipark-website/policy/page',
      }
    }
  },
  methods: {
    handleItemClick(item) {
      window.open(`${window.location.origin}/investmentPolicy/policyDetail/${this.$route.params.id}?id=${item.id}`)
      // this.$router.push({ name: 'PolicyDetail', params: { id: this.$route.params.id }, query: { id: item.id } })
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/style/var.less";
.no-data{
  position: fixed;
  bottom: 0;
}
.container {
  // display: flex;
  // flex-direction: column;
  height: 100%;
  padding-top: @headHeight;
  // .policy {
  //   // flex: 1;
  //   // height: calc(100% - 100px);
  //   height: 100%;
  //   // display: flex;
  //   // flex-direction: column;
  //   .main {
  //     // flex: 1;
  //     height: calc(100% - 64px)
  //   }
  // }
}
</style>