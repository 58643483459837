<template>
  <div :class="['list', spinning ? 'center' : '']">
    <a-spin
      :spinning="spinning"
      style="flex: 1; box-sizing: border-box;"
    >
      <template v-if="hasData">
        <div
          class="list-item"
          v-for="item in list"
          :key="item.id"
          @click="handleItemClick(item)"
        >
          <div class="list-item-top">
            <div class="list-item-top-title">{{item.title}}</div>
            <div class="list-item-top-date">{{item.updateDate || item.publishDate}}</div>
          </div>
          <div class="list-item-center multi-ellipsis">
            {{trimSpace(item.content).length > 100 ? (trimSpace(item.content).slice(0, 100) + '...') : trimSpace(item.content) }}
          </div>
        </div>
      </template>

    </a-spin>
    <template v-if="hasData">
      <div class="pagination">
        <a-pagination
          v-model="pageNum"
          :pageSize="5"
          :total="total"
          style="display:inline-block"
        />
      </div>
    </template>

    <NoData v-if="!hasData" />

  </div>
</template>

<script>
import NoData from '@/components/NoDate'
import { trimSpace } from '@/utils/util'
export default {
  components: {
    NoData
  },
  props: {
    hasData: {
      type: Boolean,
      default: true
    },
    requestConfig: {
      type: Object,
      required: true
    },
    handleResultData: {
      type: Function,
      default: r => r
    }
  },
  data() {
    return {
      list: [],
      pageNum: 1,
      total: 0,
      spinning: false,
    }
  },
  created() {

  },
  watch: {
    pageNum: {
      handler(val) {
        this.spinning = true
        this.$request({
          ...this.requestConfig,
          data: {
            ...this.requestConfig.data,
            pageNum: val,
            pageSize: 5
          }
        })
          .then(res => {
            const data = this.handleResultData(res)
            this.list = data.list || []
            this.total = data.total
            if (val === 1 && !this.list.length) {
              this.$emit('update:hasData', false)
            }else {
              this.$emit('update:hasData', true)
            }
          })
          .catch(() => {
            this.$emit('update:hasData', false)
          })
          .finally(() => this.spinning = false)
      },
      immediate: true
    }
  },
  methods: {
    trimSpace,
    handleItemClick(item) {
      this.$emit('itemClick', item, {
        number: item.number
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/style/var.less";
.multi-ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.list {
  position: relative;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // min-height: 100%;
  word-break: break-all;

  padding-bottom: 203px;
  min-height: calc(100% - 164px);
  &-item {
    padding-top: 48px;
    &:hover {
      .list-item-top .list-item-top-title,
      .list-item-top-date,
      .list-item-center {
        color: #4d7fd0;
      }
    }
    &-top {
      display: flex;
      justify-content: space-between;
      &-title {
        font-size: 24px;
        color: #393a3b;
        line-height: 33px;
        font-weight: bold;
        // &:hover{
        //   color: #4d7fd0;
        // }
      }

      &-date {
        font-size: 16px;
        color: #909092;
      }
    }
    &-center {
      padding-top: 16px;
      font-size: 16px;
      line-height: 22px;
      color: #393a3b;
      // &:hover{
      //     color: #4d7fd0;
      //   }
    }
    // .multi-ellipsis(3);
  }
  .pagination {
    text-align: center;
    padding: 104px 0 60px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    /deep/.ant-pagination-item-active a {
        background-color: #1677ff;
        border: #1677FF;
        color: #fff;
      }
  }
}
</style>