<!--
 * @Author: WangHan
 * @Date: 2020-12-17 10:00:46
 * @LastEditors: WangHan
 * @LastEditTime: 2020-12-21 12:56:30
 * @Description: 
-->
<template>
  <div class="container">
    <DynamicAndPolicyDetail
      :requestConfig="requestConfig"
      :formatResultData="formatResultData"
      :hasData.sync="hasData"
    />
    <!-- <BottomInfo :class="!hasData ? 'no-data' : ''" /> -->
  </div>

</template>

<script>
// import BottomInfo from '@/components/BottomInfo'
import DynamicAndPolicyDetail from '@/components/DynamicAndPolicyDetail'
export default {
  components: {
    // BottomInfo,
    DynamicAndPolicyDetail
  },
  created() {
  },
  data() {
    return {
      hasData: true,
      requestConfig: {
        url: '/ipark-website/websiteiparkdynamic/dynamicDetail',
        data: {
          number: this.$route.query.number,
          dynamicId: this.$route.query.id,
        }
      }
    }
  },
  methods: {
    formatResultData({ data = {} }) {
      const res = {
        title: data.currentTitle,
        publishDate: data.currentUpdateDate,
        content: data.currentContent,
      }
      if (data.previousId) {
        res.before = {
          id: data.previousId,
          title: data.previousTitle,
          number: data.previousNumber,
        }
      }
      if (data.nextId) {
        res.next = {
          id: data.nextId,
          title: data.nextTitle,
          number: data.nextNumber,
        }
      }
      return res
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/style/var.less";
.no-data{
  position: fixed;
  bottom: 0;
}
.container {
  // display: flex;
  // flex-direction: column;
  height: 100%;
  padding: 64px 0 0;
}
</style>