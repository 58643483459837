<template>
  <div class="container">
    <Head />
    <!-- <div class="dynamic"> -->
      <ParkBreadcrumb/>
      <!-- <div class="main"> -->
        <List 
          class="main"
          :hasData.sync="hasData"
          :requestConfig="requestConfig" 
          :handleResultData="res => res.data.pageQuery"
          @itemClick="handleItemClick"
          />
      <!-- </div> -->
    <!-- </div> -->
    <BottomInfo :class="!hasData ? 'no-data' : ''"/>
  </div>
</template>

<script>
import List from '@/components/List'
import BottomInfo from '@/components/BottomInfo'
import Head from '@/components/Head';
import ParkBreadcrumb from '@/components/ParkBreadcrumb'
export default {
  name: 'ParkDynamics',
  components: {
    List,
    BottomInfo,
    Head,
    ParkBreadcrumb
  },
  data() {
    return {
      hasData: true,
      requestConfig: {
        url: '/ipark-website/websiteiparkdynamic/dynamicPageList',
      }
    }
  },
  methods: {
    handleItemClick(item, pageInfo) {
      window.open(`${window.location.origin}/parkDynamics/parkDynamicsDetail/${this.$route.params.id}?id=${item.id}&number=${pageInfo.number}`)
      // this.$router.push({ name: 'ParkDynamicsDetail', params: { id: this.$route.params.id }, query: { id: item.id, number: pageInfo.number } })
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/style/var.less";
.no-data{
  position: fixed;
  bottom: 0;
}
.container {
  height: 100%;
  padding-top: @headHeight;
    // .main {
    //   height: calc(100% - 64px)
    // }
  
}
</style>