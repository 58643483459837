<template>
  <div class="container">
    <Head :phone="detail.contractPhone" />
    <ParkBreadcrumb />
    <div class="content main" v-if="detail.id">
      <div class="info mBottom48">
        <div class="left">
          <a-carousel :dots="false" arrows autoplay>
            <div
              slot="prevArrow"
              class="custom-slick-arrow"
              style="left: 20px;zIndex:1"
            >
              <a-icon type="left"  class="left-icon"/>
            </div>
            <div 
              slot="nextArrow" 
              class="custom-slick-arrow"
              style="right: 20px"
            >
              <a-icon type="right" />
            </div>
            <div 
              @click.stop="showBigPic(index)"
              v-for="(item,index) in detail.photos" 
              :key="index" 
              :style="`height: 422px; background:url(${item}); background-size: cover;cursor: pointer;`">
            </div>
          </a-carousel>
        </div>
        <div class="right">
          <div class="info-title">{{houseName[detail.houseType] + detail.title}}</div>
          <div class="info-price">{{detail.priceText}}</div>
          <div class="info-typeBox">
            <div class="typeBox-item">
              <p class="typeBox-text">{{detail.houseAreaText}}</p>
              <p class="typeBox-type">房源面积</p>
            </div>
            <div>
              <p class="typeBox-text">{{rentType[detail.rentType]}}</p>
              <p class="typeBox-type">租售类型</p>
            </div>
            <div>
              <p class="typeBox-text">{{detail[`${houseTypeText[detail.houseType]}`]}}</p>
              <p class="typeBox-type">{{houseType[detail.houseType]}}</p>
            </div>
          </div>
          <div class="info-line"></div>
          <div class="info-base">
            <div class="info-base-item" v-for="(item, index) in houseTypeOption[houseType[detail.houseType]]" :key="index">
              <span class="info-base-item-text">{{item.text}}</span>
              <span class="info-base-item-key">{{get(detail,`[${item.key}]`,'暂无数据')}}</span>
            </div>
          </div>
          <div class="info-user">
            <div class="info-user-pic" :style="{backgroundImage:`url(${detail.showLogoUrl})`}"></div>
            <div>
              <p class="info-user-name">{{detail.contractName}}</p>
              <p class="info-user-phone">{{detail.contractPhone}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="description mBottom48">
        <div class="title-style">房源描述</div>
        <div class="description-text">
          {{get(detail,'description','暂无数据')}}
        </div>
      </div>
      <div class="facility mBottom48" v-if="detail.labels">
        <div class="title-style">惠企配套</div>
        <div class="facility-content">
          <div class="facility-item" v-for="(v,index) in detail.labels.split(',')" :key="index">
            <img class="facility-icon" width="42px" height="40px"  :src=" v==='宽带网络' ? iconKey[v][1] : iconKey[v] " >
            <p class="facility-text">{{v}}</p>
          </div>
        </div>
      </div>
      <div class="mapBox">
        <div class="title-style">园区位置</div>
        <div class="address"><a-icon type="environment"  style="margin-right:15px;color:#D8D8D8" /> 
          <span>{{get(detail,'province') + get(detail,'city') + get(detail,'area') + get(detail,'town') + detail.address}}</span>
        </div>
        <Amap
          :marker="[detail.longitude,detail.latitude]"
        ></Amap>
      </div>
    </div>
    <Spinning v-if="spinning" :spinning="spinning" tip="房源详情加载中..."></Spinning>
    <div v-if="!detail.id && !spinning">
      <NoData :isNeedButton="false" text="该内容已下架，暂时无法浏览"></NoData>
    </div>
    <BottomInfo :class="!detail.id ? 'flexBottom' : ''"></BottomInfo>
    <BigPicSwiper v-if="dialog" :dialog.sync="dialog" :photos="detail.photos" :defIndex="defIndex"></BigPicSwiper>
  </div>
</template>

<script>
import {iconKey} from '@/config/huiqi';
import Amap from '@/components/Amap';
import BottomInfo from '@/components/BottomInfo';
import Spinning from '@/components/Spinning';
import ParkBreadcrumb from '@/components/ParkBreadcrumb';
import NoData from '@/components/NoDate';
import BigPicSwiper from '@/components/BigPicSwiper';
import Head from '@/components/Head';
import {get} from 'lodash';
import {houseTypeOption, houseType, houseName, houseTypeText, rentType} from './enums';
export default {
  name:'InfoDetail',
  components: {
    Amap,
    BottomInfo,
    Spinning,
    ParkBreadcrumb,
    Head,
    NoData,
    BigPicSwiper
  },
  data() {
    return {
      houseTypeOption,
      houseType,
      houseName,
      houseTypeText,
      rentType,
      iconKey,
      defIndex: 0, // 显示大图的初始下标
      dialog: false,
      detail: {},
      spinning: false,
    }
  },
  created() {
    this.getDetailData()
  },
  methods: {
    get,
    getDetailData() {
      this.spinning = true
      const id = this.$route.query.id
      this.$request({
        method: 'get',
        url: `/ipark-website/info/detail/${id}`
      })
      .then(({data = {}})=>{
        this.detail = data
      })
      .catch(err=>{
        console.log(err);
      })
      .finally(()=>{
        this.spinning = false
      })
    },
    showBigPic(index) {
      this.defIndex = index
      this.dialog = true
    },
    hideDialog() {
      this.dialog = false
    },
  },
}
</script>

<style lang="less" scoped>
@import '@/style/var.less';
.container {
  padding-top: @headHeight;
  .flexBottom {
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .content {
    padding-top: 48px;
    padding-bottom: 60px;
    background-color: #fff;
    line-height: 1.4;
    color: #393A3B;
    .info {
      height: 422px;
      display: flex;
      .left {
        height: 100%;
        width: 750px;
        margin-right: 32px;
      }
      .right {
        height: 100%;
        width: 418px;
        position: relative;
      }
      &-title {
        font-size: 28px;
        // margin-bottom: 17px;
      }
      &-price {
        margin: 10px 0 10px;
        font-size: 32px;
        color: #FF4730;
      }
      &-typeBox {
        display: flex;
        justify-content: space-between;
        .typeBox-text {
          margin-bottom: 8px;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
        }
        .typeBox-type {
          text-align: center;
          color: #909092;
        }
      }
      &-line {
        height: 1px;
        margin: 16px 0;
        background-color:#DADBDC;
      }
      &-base {
        display: flex;
        flex-wrap: wrap;
        &-item {
          min-width: 50%;
          margin-bottom: 7px;
          font-size: 14px;
          &-text {
            color: #909092;
          }
        }
      }
      &-user {
        position: absolute;
        bottom: 0;
        left: 0;
        border: 1px solid #DADBDC;
        width: 100%;
        height: 80px;
        padding: 15px 23px;
        display: flex;
        &-pic {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin-right: 15px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
        &-name {
          line-height: 1;
          margin-bottom: 9px;
        }
        &-phone {
          font-size: 20px;
          color: #1677FF;
        }
      }
    }
    .description {
      &-text {
        color:#393A3B;
        line-height: 1.7;
      }
    }
    .facility {
      &-item {
        display: inline-block;
        margin-right: 50px;
        text-align: center;
      }
      &-text {
        margin-top: 8px;
      }
    }
    .mapBox {
      .address {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
      }
    }
  }
}
.title-style {
  font-size: 24px;
  margin-bottom: 16px;
}
.mBottom48 {
  margin-bottom: 48px;
}
/deep/ .ant-carousel {
    .slick-slide {
      text-align: center;
      height: 422px;
      line-height: 422px;
      overflow: hidden;
      h3 {
        color: #fff;
      }
  }
  .custom-slick-arrow {
    display: flex!important;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    font-size: 24px;
    color: #909092 ;
    background: #F4F6F8;
    border-radius: 50%;
    opacity: 0.5;
    &:before {
      display: none;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>