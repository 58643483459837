/*
 * @Author: huangxingli
 * @Date: 2020-12-16 18:34:01
 * @LastEditors: huangxingli
 * @LastEditTime: 2020-12-21 15:23:37
 * @Description: 
 */
// import way from '../assets/comment/mating_ic_way.png'
import way from '../assets/comment/mating_ic_way.svg'
import sewage from '../assets/comment/mating_ic_sewage.svg'
import gas from '../assets/comment/mating_ic_gas .svg'
import power from '../assets/comment/mating_ic_power .svg'
import heat from '../assets/comment/mating_ic_heat.svg'
import steam from '../assets/comment/mating_ic_steam.svg'
import communicate from '../assets/comment/mating_ic_communicate.svg'
import broadband from '../assets/comment/mating_ic_broadband.svg'
import broadbandPng from '../assets/comment/mating_ic_broadband.png'
import tv from '../assets/comment/mating_ic_tv.svg'
import land from '../assets/comment/mating_ic_land.svg'
import plant from '../assets/comment/mating_ic_plant.svg'
import reduction from '../assets/comment/mating_ic_reduction.svg'
const iconKey = {
	'道路': way,
	'污水': sewage,
	'天然气': gas,
	'供电': power,
	'供热': heat,
	'蒸汽': steam,
	'通讯': communicate,
	'宽带网络': [broadband,broadbandPng],
	// '宽带网络': broadbandPng,
	'有线电视': tv,
	'土地平整': land,
	'无尘车间': plant,
	'降噪': reduction,
}
export  {
	iconKey
}