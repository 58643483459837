<template>
  <!-- <a-config-provider :locale="zhCN"> -->
    <div class="container">
      <Head></Head>
      <div class="classify">
        <div class="main">
          <a-tabs v-model="houseType" :tabBarStyle="{height:'64px',borderWidth:0}" @change="callback" >
            <a-tab-pane key="1" tab="厂房招商">
            </a-tab-pane>
            <a-tab-pane key="2" tab="仓库招商">
            </a-tab-pane>
            <a-tab-pane key="3" tab="写字楼招商">
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
      <div class="content main" v-if="infoList.length>0">
        <a-spin :spinning="loading">
          <div class="cardBox">
            <div class="crad" v-for="(item, index) in infoList" :key="index" @click="toInfoDetail(item.id)">
            <div class="picture" :style="{backgroundImage:`url(${item.houseUrl})`}"></div>
            <div class="info">
              <div class="info-title ellipsis">{{item.title}}</div>
              <div class="info-type">
                <span class="info-houseType">{{item.specificTypeText}}</span>
                <span 
                  class="info-rentType" 
                  :style="{
                    backgroundColor:item.rentType === 1 ? 'rgba(255, 179, 87,0.1)' : 'rgba(99, 164, 255,.1)',
                    color:item.rentType === 1 ? '#FFB357' : '#63A4FF'
                  }"
                >
                  {{item.rentType === 1 ? '出租' : '出售'}}
                </span>
              </div>
              <div class="info-number">
                <span class="info-price">{{item.priceText}}</span>
                <span class="info-area">{{item.houseAreaText}}</span>
              </div>
            </div>
          </div>
          </div>
          <div class="pagination">
            <a-pagination v-model="pageNum" :total="total" :defaultPageSize="9" @change="pageChange" style="display:inline-block" />
          </div>
        </a-spin>
      </div>
      <Spinning v-if="loading" :spinning="loading"></Spinning>
      <div v-if="infoList.length === 0 && !loading">
        <NoData :isNeedButton="false"></NoData>
      </div>
      <BottomInfo :class="infoList.length === 0 ? 'flexBottom' : ''"></BottomInfo>
    </div>
  <!-- </a-config-provider> -->
</template>

<script>
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';
import BottomInfo from '@/components/BottomInfo';
import NoData from '@/components/NoDate';
import Spinning from '@/components/Spinning';
import Head from '@/components/Head';
export default {
  name:"InvestmentInfo",
  components:{
    BottomInfo,
    NoData,
    Spinning,
    Head
  },
  data() {
    return {
      zhCN,
      houseType: '1',
      pageNum: 1,
      pageSize: 9,
      total: 0,
      infoList: [],
      loading: false
    }
  },
  created() {
    this.getInvestmentInfo()
  },
  methods: {
    getInvestmentInfo() {
      const {houseType, pageSize, pageNum} = this
      this.loading = true
      this.$request({
        url:'/ipark-website/info/page',
        data: {
          houseType,
          pageSize,
          pageNum
        }
      }).then(({data = {}})=>{
        this.infoList = data.list
        this.pageNum = data.pageNum
        this.total = data.total
      }).finally(()=>{
        this.loading = false
      })
    },
    pageChange() {
      this.getInvestmentInfo()
    },
    callback(){
      this.pageNum = 1
      this.getInvestmentInfo()
    },
    toInfoDetail(id) {
      window.open(`${window.location.origin}/investmentInfo/infoDetail/${this.$route.params.id}?id=${id}`)
      // this.$router.push({ name: 'InfoDetail', params: { id: this.$route.params.id }, query: { id } })
    },
  },
}
</script>

<style lang="less" scoped>
@import '@/style/var.less';
.container {
  width: 100%;
  background-color: #F7F9FA;
  padding-top: @headHeight;
  .classify {
    width: 100%;
    height: 64px;
    background-color: #fff;
    .main {
      /deep/ .ant-tabs-tab{
        padding: 21px 0px;
      }
      /deep/ .ant-tabs-tab-active {
        color: #1677FF;
      }
    }
  }

  .content {
    padding-top: 48px;
    padding-bottom: 60px;
    min-height: calc(100vh - @headHeight - 116px - 48px);
    display: flex;
    /deep/.ant-spin-nested-loading {
      flex: 1;
      display: flex;
    }
    /deep/.ant-spin-container {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .cardBox {
      flex: 1;
    }
    .crad {
      width: 390px;
      height: 402px;
      background-color: #fff;
      display: inline-block;
      margin: 0 15px 15px 0;
      cursor: pointer;
      .picture {
        height: 256px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      .info {
        height: 146px;
        padding: 24px;
        padding-top: 18px;
        span {
          display: inline-block;
        }
        &-title {
          font-size: 18px;
          color: #393A3B;
          font-weight: bold;
          margin-bottom: 8px;
        }
        &-type {
          height: 22px;
          margin-bottom: 16px;
        }
        &-houseType {
          color: #325280;
          margin-right: 8px;
        }
        &-rentType {
          font-size: 12px;
          width: 44px;
          text-align: center;
          line-height: 22px;
        }
        &-price {
          font-size: 22px;
          color: #FF4730;
          margin-right: 8px;
        }
        &-area {
          color: #909092;
          vertical-align: sub;
        }
      }
    }
    .crad:nth-child(3n) {
      margin: 0;
    }
    .pagination {
      text-align:center;
      margin-top: 60px;
      height: 32px;
      /deep/.ant-pagination-item-active a {
        background-color: #1677ff;
        border: #1677FF;
        color: #fff;
      }
    }
  }
  .flexBottom {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>