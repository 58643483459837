<!--
 * @Author: yejincheng
 * @Date: 2020-12-19 17:35:16
 * @LastEditors: yejincheng
 * @LastEditTime: 2020-12-21 18:07:24
 * @Description: 
-->
<template>
  <div class="head">
    <div class="main">
      <div class="head-left">
        <router-link
          :to="`/${webName}`"
          :class="['index', 'ParkDetail'].includes(pathName) ? 'currtnt' : ''">
					首页
				</router-link>
        <router-link
          :to="`/investmentInfo/${webName}`"
          :class="['InvestmentInfo', 'InfoDetail'].includes(pathName) ? 'currtnt' : ''">
					招商信息
				</router-link>
        <router-link
          :to="`/parkDynamics/${webName}`"
          :class="['ParkDynamics', 'ParkDynamicsDetail'].includes(pathName)? 'currtnt': ''">
					园区动态
				</router-link>
        <router-link
          :to="`/investmentPolicy/${webName}`"
          :class="['InvestmentPolicy', 'PolicyDetail'].includes(pathName)? 'currtnt': ''">
					招商政策
				</router-link>
      </div>
      <div class="head-right" v-if="phone"> 
        <a-icon type="phone" theme="filled" />招商热线：{{phone}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Head',
  created() {
    this.$request({
      url: '/ipark-website/website/getPhone'
    })
    .then(({data = {}}) => {
      this.phone = data.phone
      document.title = data.officialName || '走进园区'
    })
  },
  data() {
    return {
      phone:'',
      pathName: this.$route.name,
      webName: this.$route.params.id,
    };
	},
};
</script>

<style lang="less" scoped>
@import '../style/var.less';
.head {
  background-color: #454955;
  height: @headHeight;
  line-height: @headHeight;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  .main {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 16px;
  }
  .head-right {
    height: 100%;
    text-align: right;
    i{
      transform-origin: center center;
      transform: rotate(109deg) translate(-1px, 1px);
      font-size: 12px;
      margin-right: 5px;
    }
  }
  .head-left {
    a {
      display: inline-block;
      width: 112px;
      color: #fff;
      text-align: center;
    }
    .currtnt {
      color: #1677ff;
      background-color: #393a3b;
    }
  }
}
</style>
