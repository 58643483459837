<template>
  <a-spin
    :spinning="spinning"
    :tip="tip"
  >
    <div
      style="padding: 300px 0;"
      class="main"
    >
    </div>
  </a-spin>
</template>

<script>
export default {
  name: 'Spinning',
  props: {
    spinning: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String,
      default: '数据加载中...'
    }
  }
}
</script>

<style>
</style>