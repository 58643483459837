<!--
 * @Author: WangHan
 * @Date: 2020-12-17 10:00:46
 * @LastEditors: WangHan
 * @LastEditTime: 2020-12-21 12:56:17
 * @Description: 
-->
<template>
  <div class="container">
    <DynamicAndPolicyDetail :requestConfig="requestConfig" :hasData.sync="hasData"/>
    <!-- <BottomInfo :class="!hasData ? 'no-data' : ''"/> -->
  </div>

</template>

<script>
// import BottomInfo from '@/components/BottomInfo'
import DynamicAndPolicyDetail from '@/components/DynamicAndPolicyDetail'
export default {
  components: {
    // BottomInfo,
    DynamicAndPolicyDetail,
  },
  created() {
    this.requestConfig = {
        url: `/ipark-website/policy/detail/{id}`,
        method: 'get',
        pathField: 'id',
        data: {
          id: this.$route.query.id
        }
    }
  },
  data() {
    return {
      hasData: true,
      requestConfig: {}
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
@import "@/style/var.less";
.no-data{
  position: fixed;
  bottom: 0;
}
.container {
  // display: flex;
  // flex-direction: column;
  height: 100%;
  // box-sizing: border-box;
  padding: 64px 0 0;
}
</style>