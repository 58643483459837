<!--
 * @Author: WangHan
 * @Date: 2020-12-17 10:00:46
 * @LastEditors: WangHan
 * @LastEditTime: 2020-12-19 16:14:03
 * @Description: 
-->
<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <keep-alive include="InvestmentInfo">
        <router-view></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route() {
      this.statisticsOpenTimes();
    }
  },
  mounted() {
    this.statisticsOpenTimes();
  },
  methods:{
    // 统计官网打开次数
    statisticsOpenTimes(){
      this.$request({
        url:'/ipark-website/website/addCount',
        method: 'post',
        data: {
          type: 1
        }
      })
    }
  }
}
</script>

<style>
@import './style/base.less';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>
