/*
 * @Author: yejincheng
 * @Date: 2020-12-17 14:55:23
 * @LastEditors: yejincheng
 * @LastEditTime: 2020-12-17 15:29:32
 * @Description: 
 */
const houseTypeOption = {
  '厂房类型': [{
    text: '厂房楼层：',
    key: 'plantFloorText'
  }, {
    text: '厂房结构：',
    key: 'plantStructText'
  }, {
    text: '新旧程度：',
    key: 'plantQualityText'
  }],
  '仓库类型': [{
    text: '底层层高：',
    key: 'storeBaseHighText'
  }, {
    text: '仓库结构：',
    key: 'storeStructText'
  }, {
    text: '地坪功能：',
    key: 'storeFunctionText'
  }, {
    text: '地坪材质：',
    key: 'storeTextureText'
  }],
  '装修程度': [{
    text: '户型朝向：',
    key: 'officeOrientationText'
  }, {
    text: '工位数量：',
    key: 'officeStationText'
  }, {
    text: '楼层信息：',
    key: 'officeFloorInfo'
  }, {
    text: '物业费：',
    key: 'officePropertyCost'
  }, {
    text: '空调费：',
    key: 'officeAirCost'
  }, {
    text: '停车费：',
    key: 'officeParkCost'
  }]
}
const houseName = {
  '1': '厂房-',
  '2': '仓库-',
  '3': '写字楼-'
}
const houseType = {
  '1': '厂房类型',
  '2': '仓库类型',
  '3': '装修程度'
}
const rentType = {
  '1': '出租',
  '2': '出售'
} 
const houseTypeText = {
  '1': 'plantTypeText',
  '2': 'storeTypeText',
  '3': 'officeQualityText'
}
export {
  houseTypeOption,
  houseType,
  houseName,
  houseTypeText,
  rentType
}