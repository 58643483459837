<!--
 * @Author: WangHan
 * @Date: 2020-12-17 17:36:19
 * @LastEditors: WangHan
 * @LastEditTime: 2020-12-21 13:11:54
 * @Description: 园区动态/招商政策详情
-->
<template>
  <div class="box">
    <Head />
    
    <a-spin
    :spinning="spinning"
    class="spinning-style"
  >
    <ParkBreadcrumb/>
    <!-- <Head />
    <ParkBreadcrumb /> -->
    <div class="main" v-if="hasData">
      <div class="top">
        <div class="title">{{detailObj.title}}</div>
        <div class="date">发布时间：{{detailObj.publishDate}}</div>
        <div class="line"></div>
        <div class="content" v-html="detailObj.content">
        </div>
      </div>
      <div class="bottom">
        <div class="btn-box" >
          <div
            class="last"
            v-if="detailObj.before"
            @click="goLastOrNext(detailObj.before)"
          >
            <div class="last-center">
              <div class="last-icon">
                <a-icon type="left"></a-icon>
              </div>
              <div class="last-title">
                <div>上一篇：</div>
                <div>{{ detailObj.before.title }}</div>
              </div>
            </div>
          </div>
          <div
            class="next"
            v-if="detailObj.next"
            @click="goLastOrNext(detailObj.next)"
          >
            <div class="next-center">
                <div class="next-title">
                  <div>下一篇：</div>
                  <div>{{ detailObj.next.title }}</div>
                </div>
              <div class="next-icon">
                <a-icon type="right"></a-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-data" v-if="!hasData">
      <NoData text='该内容已下架，暂时无法浏览' :isNeedButton="false"></NoData>
    </div>
    <BottomInfo />
  </a-spin>
  </div>
  

</template>

<script>
import { cloneDeep } from 'lodash'
import Head from '@/components/Head';
import ParkBreadcrumb from '@/components/ParkBreadcrumb'
import NoData from '@/components/NoDate'
import {transformTextAreaText} from '@/utils/util'
import BottomInfo from '@/components/BottomInfo'
export default {
  components: {
    Head,
    ParkBreadcrumb,
    NoData,
    BottomInfo
  },
  props: {
    requestConfig: {
      type: Object,
      require: true
    },
    formatResultData: {
      type: Function,
      default: r => r.data
    },
  },
  data() {
    return {
      spinning: false,
      detailObj: {},
      hasData: false
    }
  },
  created() {
    this.getDetail(cloneDeep(this.requestConfig))
  },
  methods: {
    transformTextAreaText,
    getDetail(requestConfig) {
      this.spinning = true
      this.$request({
        ...requestConfig,
      })
        .then(res => {
          this.hasData = true
          this.detailObj = this.formatResultData(res)
          this.detailObj.content = transformTextAreaText(this.detailObj.content)
        })
        .catch(() => {
          this.hasData = false
        })
        .finally(() => this.spinning = false)
    },
    goLastOrNext(item) {
      const requestConfig = this.requestConfig
      const { dynamicId, id } = requestConfig.data
      const params = {
        ...this.requestConfig,
      }
      // 园区动态
      if (dynamicId) {
        params.data = {
          dynamicId: item.id,
          number: item.number
        }
        this.$router.push({ name: 'ParkDynamicsDetail', params: { id: this.$route.params.id }, query: { id: item.id, number: item.number } })
      }
      if (id) {
        params.data = {
          id: item.id
        }
        this.$router.push({ name: 'PolicyDetail', params: { id: this.$route.params.id }, query: { id: item.id } })
      }
      this.getDetail(params)
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/style/var.less";
/deep/ .ant-spin-container {
  height: 100%;
}
.box {
  // display: flex;
  // flex-direction: column;
  width: 100%;
  height: 100%;
  // flex: 1;
  // min-height: 700px;
}
.spinning-style {
  height: 100%;
}
.no-data {
  height: calc(100% - 164px);
}
.main {
  position: relative;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  min-height: calc(100% - 164px);
  padding: 48px 0 212px;
  // box-sizing: content-box;
  word-break: break-all;
  // height: 100%;
  // .top {
  //   flex: 1;
  // }
  
  .title {
    text-align: center;
    font-size: 32px;
    color: #393a3b;
    font-weight: bold;
  }
  .date {
    padding: 8px 0 32px;
    font-size: 16px;
    color: #909092;
    text-align: center;
  }
  .line {
    background: #dadbdc;
    height: 1px;
  }
  .content {
    padding: 30px 0 0px;
    font-size: 18px;
    color: #393a3b;
  }
  .bottom {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    padding: 60px 0 72px;
    .btn-box {
      display: flex;
      justify-content: space-between;
      width: 948px;
      margin: auto;
      .last,
      .next {
        display: flex;
        align-items: center;
        width: 450px;
        height: 80px;
        background: #f7f9fa;
      }
      .last:hover,
      .next:hover {
        background: #f7f9fa;
        box-shadow: 0 0 9px 3px rgba(57, 58, 59, 0.16);
        .last-icon,
        .next-icon {
          color: #1677ff;
        }
        .last-title,
        .next-title {
          color: #325280;
          font-weight: bold;
        }
      }
      .last-center,
      .next-center {
        display: flex;
        height: 100%;
      }
      .next-center {
        width: 100%;
        justify-content: space-between;
      }
      .last-icon,
      .next-icon {
        display: flex;
        align-items: center;
        height: 100%;
        box-sizing: border-box;
        font-size: 17px;
        color: #909092;
      }
      .last-icon {
        padding: 0 14px 0 32px;
      }
      .next-icon {
        padding: 0 32px 0 14px;
      }
      .last-title,
      .next-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 16px;
        color: #393a3b;
      }
      .next-title {
        padding: 0 0 0 24px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
