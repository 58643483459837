import 'babel-polyfill'
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import request from "./services/request";
import {
  ConfigProvider,
  Tabs, 
  Spin, 
  Pagination, 
  Carousel, 
  Icon,
  Breadcrumb,
  Steps,
  Button
} from 'ant-design-vue';

import VueAMap from 'vue-amap'
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: window.location.host.startsWith('localhost') ? '999363cf917a7131ed95a9dfc0a837a2' : '72f65ca954e6c30a067d963d3cbd2de0',
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
  v: '1.4.4',
  uiVersion: '1.0'
})
Vue.config.productionTip = false

// 局部导入所需ant组件
const antComponents = [
  ConfigProvider,
  Icon,
  Tabs,
  Spin,
  Pagination,
  Breadcrumb,
  Icon,
  Steps,
  Carousel,
  Button
]
antComponents.forEach(v => {
  Vue.use(v)
})


Vue.mixin({
  data() {
    return {
      CancelTokens: []
    }
  }
})

Vue.prototype.$request = request;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
