<template>
  <div class="index-content">
    <div v-if="!noData">
    <Head />
    <div class="content" v-if="detail.id">
    <div class="banner" >
      <img src="../../assets/home_page_banner.png" alt="" srcset="" />
    </div>
      <!-- 园区概况 -->
      <div class="park-detail">
        <div class="park-title main">园区概况</div>
        <div class="main park-one">
          <div
            class="park-left"
            :style="{
              backgroundImage: `url(${get(detail, 'photoList[0].showUrl')})`,
            }"
          ></div>
          <div class="park-right">
            <div class="top">
              <img :src="detail.showLogoUrl" alt="" srcset="" />
              <div class="top-content">
                <div class="title">{{ detail.officialName }}</div>
                <div class="tag">
                  <span v-for="(v, idx) in detail.positionLabels.slice(0, 3)" :key="idx">{{
                    v
                  }}</span>
                </div>
              </div>
            </div>
            <div class="des"  v-if="get(detail, 'description', '').length > 100">
              {{ detail.description.slice(0, 100) }}...
            </div>
            <div class="des" v-else> {{get(detail, 'description')}} </div>
            <a-button class="more"  @click="toWebsiteDetail" ghost>了解更多<a-icon type="right" /></a-button>
          </div>
        </div>
      </div>
      <!-- 招商信息 -->
      <div class="investment-info" v-if="investmentInfoList.length > 0">
        <div class="main">
          <div class="park-title">招商信息</div>
          <div class="btn-box">
           <a-button class="more" v-if="showInfoMore" @click="toInvestmentInfo" ghost>了解更多<a-icon type="right" /></a-button>
          </div>
          <div class="info-box">
            <div class="item" v-for="item in investmentInfoList" :key="item.id"  @click="toInfoDetail(item)">
              <div
                class="img"
                :style="{ backgroundImage: `url(${item.houseUrl})` }"
              ></div>
              <div class="item-info">
                <div class="title">{{ item.title }}</div>
                <div class="type-box">
                  <span class="type">{{ item.specificTypeText }}</span>
                  <span class="tag" :class="item.rentType === 1 ? 'rent':'sell' ">{{ item.rentType === 1 ? '出租' : '出售'}}</span>
                </div>
                <div class="price-box">
                  <span class="price">{{ item.priceText }}</span>
                  <span class="area">{{ item.houseAreaText }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 园区动态、招商政策 -->
      <div class="dynamics-policy"  v-if="parkDynamicsList.length > 0 || investmentPolicyList.length > 0">
        <div class="dynamics main"  v-if="parkDynamicsList.length > 0">
          <div class="park-title main">园区动态</div>
          <div class="btn-box">
          <a-button class="more" v-if="showDynamicsMore" @click="toParkDynamics" ghost>了解更多<a-icon type="right" /></a-button>
          </div>
          <div class="dynamics-box">
            <a-steps
              progress-dot
              direction="vertical"
              :current="parkDynamicsList.length - 1"
            >
              <a-step
                v-for="(item, index) in parkDynamicsList"
                :key="index"
                :title="item.updateDate"
                :description="item.title"
                @click="toDynamicsDetail(item)"
              />
            </a-steps>
          </div>
          <div class="line"  v-if="investmentPolicyList.length>0"></div>
        </div>
        <div class="policy main" v-if="investmentPolicyList.length>0" >
          <div class="park-title main">招商政策</div>
          <div class="btn-box">
          <a-button class="more" v-if="showPolicyMore" @click="toInvestmentPolicy" ghost>了解更多<a-icon type="right" /></a-button>
          </div>
          <div class="policy-box">
            <div
              class="item"
              v-for="(item, index) in investmentPolicyList"
              :key="index"
              @click="toPolicyDetail(item.id)"
            >
              <div class="txt ellipsis">{{ item.title }}</div>
              <div class="time">{{ item.publishDate }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 惠企配套 -->
      <div class="huiqi" v-if="detail.labels.length > 0">
        <div class="main">
          <div class="park-title main">惠企配套</div>
          <div class="huiqi-box">
            <div class="item" v-for="v in detail.labels" :key="v">
              <img class="imgIcon" :src=" v==='宽带网络' ? iconKey[v][0] : iconKey[v] " />
              <p class="txt">{{ v }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 联系我们 -->
      <div class="connect-box">
        <div class="main">
          <div class="park-title main">联系我们</div>
          <div class="address-box">
            <span class="address">
              <div class="txt">
                <a-icon type="environment" theme="filled"/>
                {{
                get(detail, 'province') +
                get(detail, 'city') +
                get(detail, 'area') +
                get(detail, 'town') + detail.address
               }}
              </div>
              </span>
            <span class="tel">
              <a-icon type="phone" theme="filled" style="transform-origin: center;transform: rotate(105deg);"/>
              {{detail.contractName + '-' + detail.contractPhone}}
            </span>
          </div>
          <Amap
            :marker="[detail.longitude, detail.latitude]"
          ></Amap>
        </div>
      </div>
    </div>
    <Spinning v-else :spinning="spinning" tip="页面加载中..."></Spinning>
    </div>
    <div v-else style="hight:800px" class="empty-box">
      <NoDate  :isIndex="true" :text="'官网已下架，暂时无法浏览'" @butClick="toZhaoshang800"/>
    </div>
    <BottomInfo :class="noData ? 'nodata' : '' "/>
  </div>
</template>
<script>
import { get } from 'lodash';
import Head from '@/components/Head';
import api from './services';
import { iconKey } from '../../config/huiqi';
import Amap from '@/components/Amap';
import BottomInfo from '@/components/BottomInfo'
import Spinning from '@/components/Spinning';
import NoDate from '@/components/NoDate'
export default {
  name: 'index',
  components: {
    Head,
    Amap,
    BottomInfo,
    Spinning,
    NoDate
  },
  data() {
    return {
      detail: {
        positionLabels: [],
        labels:[],
        address:undefined
      },
      showPolicyMore: false,
      showInfoMore: false,
      showDynamicsMore: false,
      investmentInfoList: [],
      investmentPolicyList: [],
      parkDynamicsList: [],
      iconKey,
      spinning: false,
      noData:false,//是否下架
    };
  },
  created() {
    this.getDetailData();
    this.getPolicyList();
    this.getDynamicsList();
    this.getInfoList();
  },
  methods: {
    get,
    getDetailData() {
      this.spinning = true
      this.$request({
        ...api.indexDetail,
      }).then((res) => {
        const data = get(res, 'data', {});
        data.positionLabels = get(data, 'positionLabels', '').split(',');
        data.labels = data.labels ? data.labels.split(',') : [];        
        this.detail = data;
      })
      .catch(()=>{ 
        this.noData=true
      })
      .finally(()=>{
        this.spinning = false
      })
    },
    getPolicyList() {
      this.$request({
        ...api.investmentPolicy,
      }).then((res) => {
        const data = get(res, 'data', []);
        this.showPolicyMore = false;
        if (data.length > 4) {
          data.length = 4;
          this.showPolicyMore = true;
        }
        this.investmentPolicyList = data;
      });
    },
    getInfoList() {
      this.$request({
        ...api.investmentInfo,
      }).then((res) => {
        const data = get(res, 'data', []);
        this.showInfoMore = false;
        if (data.length > 3) {
          data.length = 3;
          this.showInfoMore = true;
        }
        this.investmentInfoList = data;
      });
    },
    getDynamicsList() {
      this.$request({
        ...api.parkDynamic,
      }).then((res) => {
        const data = get(res, 'data', []);
        this.showDynamicsMore = false;
        if (data.length > 4) {
          data.length = 4;
          this.showDynamicsMore = true;
        }
        this.parkDynamicsList = data;
      });
    },
     // 前往官网详情
    toWebsiteDetail() {
      this.$router.push({ name: 'ParkDetail', params: { id: this.$route.params.id } })
    },
    // 前往招商信息列表
    toInvestmentInfo() {
      console.log(this.$route.params.id);
      this.$router.push({ name: 'InvestmentInfo', params: { id: this.$route.params.id } })
    },
    // 招商信息详情
    toInfoDetail(item) {
      this.$router.push({ name: 'InfoDetail', params: { id: this.$route.params.id },query:{
        id:item.id,
        houseType:item.houseType
      } })
    },
    // 前往动态列表
    toParkDynamics() {
      console.log('ys1')
      this.$router.push({ name: 'ParkDynamics', params: { id: this.$route.params.id } })
    },
    // 前往政策列表
    toInvestmentPolicy() {
      this.$router.push({ name: 'InvestmentPolicy', params: { id: this.$route.params.id } })
    },
    // 前往动态详情
    toDynamicsDetail(item) {
      this.$router.push({ name: 'ParkDynamicsDetail', params: { id: this.$route.params.id }, query: { id: item.id, number: item.number } })
    },
    // 前往政策详情
    toPolicyDetail(id) {
      this.$router.push({ name: 'PolicyDetail', params: { id: this.$route.params.id }, query: { id } })
    },
    // 前往中工招商网
    toZhaoshang800() {
      location.href = 'http://www.zhaoshang800.com/'
    },
  },
};
</script>

<style lang="less" scoped>
@import './style/index.less';
</style>
