<template>
    <div class="container">
      <Head/>
      <ParkBreadcrumb/>
      <a-spin :spinning="spinning" style="flex: 1; box-sizing: border-box;position: relative;">
        <div class="main" v-if="hasData">
          <div class="title" v-if="detailObj.description" style="padding-top: 60px;">
            园区简介
          </div>
          <div v-if="detailObj.description" class="content" v-html="detailObj.description">
          </div>

          <div class="swiper">
            <a-carousel :after-change="onChange" :dots="false" arrows autoplay>
              <div
                slot="prevArrow"
                class="custom-slick-arrow"
                style="left: -80px;zIndex: 1"
                slot-scope="props"
              >
                
                <a-icon type="left" @click="handleClick(props)" class="left-icon"/>
              </div>
              <div slot="nextArrow" class="custom-slick-arrow" slot-scope="props" style="right: -80px">
                <a-icon type="right" @click="handleClick(props)"/>
              </div>
              <div 
                v-for="item in detailObj.photoList" 
                :key="item.showUrl" 
                :style="`height: 550px; background:url(${item.showUrl}); background-size: cover`">

              </div>
            </a-carousel>
          </div>

          <div class="title title-top-dist product" v-if="get(detailObj, 'positionLabels.length')">
            产品定位
          </div>
          <template v-if="get(detailObj, 'positionLabels.length')">
            <div class="product-tag-list" v-for="(item, index) in detailObj.positionLabels" :key="index">
              <div class="product-tag-list-item">
                {{item}}
              </div>
            </div>
          </template>
          <div class="title title-top-dist" v-if="detailObj.plan">
            园区规划
          </div>
          <div class="park-desc" v-if="detailObj.plan" v-html="detailObj.plan">
          </div>
        </div>
      </a-spin>
      <div v-if="!hasData" class="empty-box">
          <NoData  :isIndex="true" :text="'官网已下架，暂时无法浏览'" @butClick="toZhaoshang800"/>
        </div>
      
      <BottomInfo :class="!hasData ? 'no-data' : ''"/>
    </div>
 
  
</template>

<script>
import BottomInfo from '@/components/BottomInfo'
import {transformTextAreaText} from '@/utils/util'
import {get} from 'lodash'
import Head from '@/components/Head';
import ParkBreadcrumb from '@/components/ParkBreadcrumb'
import NoData from '@/components/NoDate'
export default {
  components: {
    BottomInfo,
    Head,
    ParkBreadcrumb,
    NoData
  },
  data() {
    return {
      spinning: false,
      detailObj: {},
      hasData: true
    }
  },
  created() {
    this.spinning = true
    this.$request({
      url: '/ipark-website/website/detail'
    })
    .then(({data = {}}) => {
      data.description = transformTextAreaText(data.description)
      data.plan = transformTextAreaText(data.plan)
      data.positionLabels = (data.positionLabels || '').split(',')
      this.detailObj = data
    })
    .catch(() => {
      this.hasData = false
    })
    .finally(() => this.spinning = false)
  },
  methods: {
    get,
    onChange(a, b, c) {
      console.log(a, b, c);
    },
    handleClick(props) {
      console.log(props)
    },
    // 前往中工招商网
    toZhaoshang800() {
      location.href = 'http://www.zhaoshang800.com/'
    },
  },
}
</script>

<style lang="less" scoped>
@import "@/style/var.less";
.no-data{
  position: fixed;
  bottom: 0;
}
/deep/ .ant-carousel {
        .slick-slide {
          text-align: center;
          height: 550px;
          line-height: 550px;
          // background: #364d79;
          overflow: hidden;
          h3 {
            color: #fff;
          }
      }
      .custom-slick-arrow {
        display: flex!important;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        font-size: 24px;
        color: #909092 ;
        background: #F4F6F8;
        border-radius: 50%;
        opacity: 0.5;
        &:before {
          display: none;
        }
        &:hover {
          opacity: 0.8;
        }
      }
}
 
/deep/ .ant-spin-container {
  height: 100%;
}
.container {
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  padding-top: @headHeight;
  height: 100%;
  .main {
    .title {
      font-size: 24px;
      color: #393a3b;
      line-height: 33px;
    }
    .content {
      padding: 16px 0;
      font-size: 18px;
      color: #393a3b;
    }
    .swiper {
      width: 1008px;
      margin: 0 auto;
    }
    .title-top-dist {
      margin-top: 48px;
    }
    // 产品定位
    .product-tag-list {
      margin-top: 16px;
      display: inline-block;
      background: rgba(50,82,128,.08);
      height: 32px;
      // width: 100%;
      &-item {
        padding: 6px 8px;
        font-size: 14px;
        line-height: 20px;
        color: #325280;
        
      }
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
    // 园区简介
    .park-desc {
      padding: 16px 0 60px;
      font-size: 18px;
      color: #393A3B;
    }
  }
}
</style>