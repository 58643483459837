<!--
 * @Author: WangHan
 * @Date: 2020-12-17 10:25:45
 * @LastEditors: yejincheng
 * @LastEditTime: 2020-12-18 19:05:35
 * @Description: 展示底部信息组件
-->
<template>
  <div class="bottom-info">
    <div class="bottom-info-text">粤ICP备 15009394号-1</div>
    <div class="bottom-info-item">深圳市伙伴行网络科技有限公司 版权所属</div>
  </div>
</template>

<script>
export default {
  name: 'BottomInfo'
}
</script>

<style lang="less" scoped>
.bottom-info {
  padding: 24px;
  width: 100%;
  min-width: 1200px;
  height: 100px;
  box-sizing: border-box;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  background-color: #454955;
  line-height: 1.4;
  &-text {
    margin-bottom: 18px;
  }
}
</style>