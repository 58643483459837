const api = {
  indexDetail:{
    url:'/ipark-website/website/detail',
    method:'post'
  },
  parkDynamic:{
    url:'/ipark-website/websiteiparkdynamic/dynamic',
    method:'post'
  },
  investmentPolicy:{
    url:'/ipark-website/home/policy',
    method:'get'
  },
  investmentInfo:{
    url:'/ipark-website/home/info',
    method:'get'
  },
}
export default api