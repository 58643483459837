import { message } from 'ant-design-vue';
import _ from 'lodash';
export function transformTextAreaText(str) {
  return str.replace(/\r?\n/g,'<br/>').replace(/\s/g,'&nbsp;')
}

export const errMsg = _.debounce(function (res) {
  message.error(res.data.msg)
}, 300, {
  'leading': true,
  'trailing': false
})
export const trimSpace = str => {
  return str.toString().replace(/\s+/g, '')
}