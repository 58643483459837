<!--
 * @Author: yejincheng
 * @Date: 2020-12-18 18:45:48
 * @LastEditors: WangHan
 * @LastEditTime: 2020-12-21 17:39:53
 * @Description: 
-->
<template>
	<div class="empty">
		<img src="../assets/comment/default_page_has_been_removed@2x.png" alt="" srcset="">
		<p class="txt">{{text}}</p>
		<div v-if="isNeedButton">
			<div class="btn" v-if="isIndex" @click="butClick">查看更多园区</div>
			<!-- <div class="btn" v-else @click="goBack">返回</div> -->
		</div>
	</div>
</template>
<script>
export default {
	name:'NoData',
	props:{
		text:{
			type:String,
			default:'暂无数据'
		},
		isIndex:{
			type:Boolean,
			default:false
		},
		isNeedButton:{
			type:Boolean,
			default:true
		}
	},
	methods:{
		butClick() {
			this.$emit('butClick')
		},
		goBack(){
			this.$router.go(-1)
		}
	}
}
</script>

<style lang="less" scoped>
.empty {
	min-width: 1200px;
  position: absolute;
  width: 100%;
  top: 50%;
	transform: translateY(-50%);
	text-align: center;
  img {
    width: 200px;
    margin: auto;
    display: block;
  }
  width: 100%;
  .txt {
		font-size: 16px;
		color: #393A3B;
		text-align: center;
  }
}
.box-higth{
	background: #F7F9FA;
}
.btn {
		display: inline-block;
		padding: 0 32px;   
		background: #1677FF;
		border-radius: 24px;
		text-align: center;
		font-size: 18px;
		color: #FFFFFF;
		height: 48px;
    line-height: 48px;
		margin: 60px auto 0;
		cursor: pointer;
  }

</style>
