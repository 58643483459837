
const routes = {
	index:{
		routeName:'index',
		breadcrumbName:'首页'
	},
	ParkDetail:{
		routeName:'ParkDetail',
		breadcrumbName:'园区概况'
	},
	InvestmentInfo:{
		routeName:'InvestmentInfo',
		breadcrumbName:'招商信息'
	},
	InfoDetail:{
		routeName:'InfoDetail',
		breadcrumbName:'招商详情'
	},
	ParkDynamics:{
		routeName:'ParkDynamics',
		breadcrumbName:'园区动态'
	},
	ParkDynamicsDetail:{
		routeName:'ParkDynamicsDetail',
		breadcrumbName:'园区动态详情'
	},
	InvestmentPolicy:{
		routeName:'InvestmentPolicy',
		breadcrumbName:'招商政策'
	},
	PolicyDetail:{
		routeName:'PolicyDetail',
		breadcrumbName:'招商政策详情'
	}
}
const roule = {
	ParkDetail:[routes.index,routes.ParkDetail],
	InvestmentInfo:[routes.index,routes.InvestmentInfo],
	InfoDetail:[routes.index,routes.InvestmentInfo,routes.InfoDetail],
	ParkDynamics:[routes.index,routes.ParkDynamics],
	ParkDynamicsDetail:[routes.index,routes.ParkDynamics,routes.ParkDynamicsDetail],
	InvestmentPolicy:[routes.index,routes.InvestmentPolicy],
	PolicyDetail:[routes.index,routes.InvestmentPolicy,routes.PolicyDetail]
}
export default roule
